import { Widgets } from "./Widgets";

export class Qlickr extends Widgets {
    // Important
    constructor(step_selector, image_selector) {
        let element = document.querySelector(step_selector);
        super(image_selector);
        let content = element.querySelector(".step-content");
        let substr = String(content.innerHTML.toString().replace(/(<([^>]+)>)|<br>|[\n\t]/ig, "").trim());

        let image = this.element.querySelector(".image");
        this.qlickr = document.createElement("div");
        this.qlickr.classList.add("qlickr-holder");
        let qlickr = document.createElement("div");
        qlickr.classList.add("qlickr");
        this.qlickr.append(qlickr);
        this.qlickr.classList.add("qlickr-holder");
        this.qlickr.style.position = "absolute";
        let hardware = image.querySelector(".hardware");


        if (substr.match(/(deslize|arraste).*(para).*(cima)/ig)) {
            qlickr.classList.add("arraste", "cima");
        }
        if (substr.match(/(deslize|arraste).*(para).*(baixo)/ig)) {
            qlickr.classList.add("arraste", "baixo");
        }
        if (substr.match(/(deslize|arraste).*(para).*(direita)/ig)) {
            qlickr.classList.add("arraste", "direita");
        }
        if (substr.match(/(deslize|arraste).*(para).*(esquerda)/ig)) {
            qlickr.classList.add("arraste", "esquerda");
        }
        this.element.addEventListener("WrappedImageResized", e => {
            if (e.scale>=1) return;
            let scale = (1 + (1 - e.scale)) * 2 ;
            this.qlickr.style.transformOrigin = "top left";
            this.qlickr.style.transform = `scale(${scale})`;
        })


        if (hardware) {
            if (Number(image.dataset.screenSizeX) > 0 && Number(image.dataset.screenSizeY) > 0) {

                this.qlickr.style.left = (Number(image.dataset.coordinatesX) + Number(image.dataset.screenPositionX)) + "px";
                this.qlickr.style.top = (Number(image.dataset.coordinatesY) + Number(image.dataset.screenPositionY)) + "px";
            }
        } else {
            this.qlickr.style.left = image.dataset.coordinatesX + "px";
            this.qlickr.style.top = image.dataset.coordinatesY + "px";
        }
        this.element.querySelector(".image-wrapper").append(this.qlickr);
    }
}
