var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"card\">\r\n\r\n            <div class=\"widget dropdown\">\r\n                <div class=\"dropdown-links\">\r\n                    <ul>\r\n                        <li>\r\n                            <a class=\"copy-url\" data-href=\""
    + alias3((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"devices",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":12,"column":59},"end":{"line":12,"column":78}}}))
    + "/"
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias3(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"topicPath","hash":{},"data":data,"loc":{"start":{"line":12,"column":95},"end":{"line":12,"column":108}}}) : helper)))
    + "\">\r\n                                <i class=\"material-icons-outlined\">insert_link</i>\r\n                                "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/most_viewed_usecases/copy-link",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":32},"end":{"line":14,"column":85}}}))
    + "\r\n                            </a>\r\n                        </li>\r\n                    </ul>\r\n                </div>\r\n            </div>\r\n\r\n            <a class=\"wrapper\" \r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":28,"column":23}}})) != null ? stack1 : "")
    + "            >\r\n                <div class=\"details\">\r\n                    "
    + alias3(((helper = (helper = lookupProperty(helpers,"topicName") || (depth0 != null ? lookupProperty(depth0,"topicName") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"topicName","hash":{},"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":31,"column":33}}}) : helper)))
    + "\r\n                    <div class=\"tech\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":24},"end":{"line":36,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"os") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":40,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\r\n                </div>\r\n                <div class=\"icon-holder\">\r\n                    <i class=\"material-icons\">chevron_right</i>\r\n                </div>\r\n            </a>\r\n        </li>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":23,"column":26},"end":{"line":23,"column":34}}}) : helper)))
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topicPath","hash":{},"data":data,"loc":{"start":{"line":23,"column":90},"end":{"line":23,"column":103}}}) : helper)))
    + "\" \r\n                    data-navigate-to=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topicPath","hash":{},"data":data,"loc":{"start":{"line":24,"column":94},"end":{"line":24,"column":107}}}) : helper)))
    + "\"\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":26,"column":26},"end":{"line":26,"column":34}}}) : helper)))
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"application",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":26,"column":34},"end":{"line":26,"column":57}}}))
    + "/"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"software") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"os") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topicPath","hash":{},"data":data,"loc":{"start":{"line":26,"column":88},"end":{"line":26,"column":101}}}) : helper)))
    + "\" \r\n                    data-navigate-to=\""
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"application",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":27,"column":38},"end":{"line":27,"column":61}}}))
    + "/"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"software") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"os") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topicPath","hash":{},"data":data,"loc":{"start":{"line":27,"column":92},"end":{"line":27,"column":105}}}) : helper)))
    + "\" \r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"device\"><span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span></div> \r\n                        <div class=\"manufacturer\"><span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span></div>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"device\"><span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"software") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span></div> \r\n                        <div class=\"manufacturer\"><span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"os") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span></div>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"pagination\" data-per-page=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"perpage") || (depth0 != null ? lookupProperty(depth0,"perpage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"perpage","hash":{},"data":data,"loc":{"start":{"line":51,"column":47},"end":{"line":51,"column":58}}}) : helper)))
    + "\">\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"pagination\" data-per-page=\"2\">\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Most Viewed Usecases Template -->\r\n<div class=\"widget most_viewed_usecases\">\r\n    <h2 class=\"title\">"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"widget/most_viewed_usecases/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":22},"end":{"line":3,"column":71}}}))
    + "</h2>\r\n    <ul class=\"panel\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"content") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":48,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"perpage") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":50,"column":4},"end":{"line":54,"column":11}}})) != null ? stack1 : "")
    + "        <div class=\"pagination-left\">\r\n            <i class=\"material-icons\">chevron_left</i>\r\n        </div>\r\n        <div class=\"pagination-right\">\r\n            <i class=\"material-icons\">chevron_right</i>\r\n        </div>\r\n    </div>\r\n</div>\r\n<!-- End Most Viewed Usecases Template -->";
},"useData":true});