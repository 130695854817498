import { Dropdown } from "./Dropdown";
import { ULPaginated } from "./ULPaginated";

export class MostViewedUsecases extends ULPaginated {
  constructor(selector, target) {
    super(selector);
  }

  init(){
    var items = this.element.querySelectorAll(".dropdown");

    const _that = this;
    if (items) {
      items.forEach(function (item, key) {
        const name = key+Math.random();
        _that.parent.widgets.register("dropdown" + name, new Dropdown(item));
      });
    }
  }
}
