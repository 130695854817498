var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"card\">\r\n            <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":8,"column":21},"end":{"line":8,"column":29}}}) : helper)))
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"categories",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":8,"column":29},"end":{"line":8,"column":51}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":8,"column":52},"end":{"line":8,"column":60}}}) : helper)))
    + "\" class=\"wrapper\" data-navigate-to=\""
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"categories",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":8,"column":96},"end":{"line":8,"column":118}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":8,"column":119},"end":{"line":8,"column":127}}}) : helper)))
    + "\">\r\n                <i class=\"qc-icon "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":9,"column":34},"end":{"line":9,"column":42}}}) : helper)))
    + "\" style=\"-webkit-mask-image: url("
    + alias4(((helper = (helper = lookupProperty(helpers,"iconImageUrl") || (depth0 != null ? lookupProperty(depth0,"iconImageUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconImageUrl","hash":{},"data":data,"loc":{"start":{"line":9,"column":75},"end":{"line":9,"column":91}}}) : helper)))
    + ");\"></i>\r\n                <p class=\"title\">\r\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":24}}}) : helper)))
    + "\r\n                </p>\r\n                <div class=\"icon-holder\">\r\n                    <i class=\"material-icons\">chevron_right</i>\r\n                </div>\r\n            </a>\r\n        </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Categories Template -->\r\n<div class=\"widget categories\">\r\n    <h2>"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/categories/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":47}}}))
    + "</h2>\r\n    <p><a href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":24}}}) : helper)))
    + alias3((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"categories",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":4,"column":24},"end":{"line":4,"column":46}}}))
    + "\" class=\"see-all navigate-to\" data-navigate-to=\""
    + alias3((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"categories",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":4,"column":94},"end":{"line":4,"column":116}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/apps/see-all",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":118},"end":{"line":4,"column":153}}}))
    + " <i class=\"material-icons\">chevron_right</i> </a></p>\r\n    <ul class=\"panel\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"content") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":18,"column":13}}})) != null ? stack1 : "")
    + "    </ul>\r\n</div>\r\n<!-- End Categories Template -->\r\n";
},"useData":true});