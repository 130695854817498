import { Dropdown } from "../../../../qelpcare-call-center-application/src/widgets/Dropdown";
import { Widgets } from "../../../../qelpcare-call-center-application/src/widgets/Widgets";

export class TrailList extends Widgets {
  constructor(element) {
    super(null);
    this.element = element;
  }
  init() {
    this.element.querySelectorAll(".dropdown").forEach((item, key) => {
      const name = key + Math.random();
      this.parent.widgets.register("dropdown" + name, new Dropdown(item));
    });
    this.content = this.element.querySelectorAll(".content");
    this.content.forEach((elem) => {
        const thumbnail = elem.querySelector(".thumbnail");
      if (thumbnail) {
        thumbnail.addEventListener("click", (e) => {
          this.openContent(e);
        });
      }
    });
  }
  openContent(e) {
    const target = e.currentTarget;
    if (target.dataset.type == "youtube") {
      this.player = document.createElement("div");
      this.player.classList.add("player-wrapper");
      this.player.innerHTML = `<iframe width="314" height="180" src="${target.dataset.url}?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

      this.modal = document.createElement("div");
      this.modal.classList.add("ehelp-widget-modal", "youtube-player-modal");

      const close_btn = document.createElement("div");
      close_btn.classList.add("close-btn");
      close_btn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/> </svg>';

      this.modal.append(close_btn);
      this.modal.append(this.player);

      close_btn.addEventListener("click", (e) => {
        this.modal.remove();
      });
      document.body.append(this.modal);
    }
  }
}
