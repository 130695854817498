import { Widgets } from "./Widgets";

export class Accordion extends Widgets {
    constructor(selector) {
        super(selector);
        this.initAccordion();
        this.simpleNavigate();
        this.accordion_list;
    }
    initAccordion() {
        this.accordion_list = document.querySelectorAll(".accordion-item");
        if (!this.accordion_list || this.accordion_list.length == 0) {
            console.warn("Accordion has initiated but no element accordion-item has been found");
            return;
        }
        this.accordion_list.forEach(element => {
            element.querySelector(".content").style.height = 0;
            element.dataset.contentheight = element.querySelector(".content .panel").offsetHeight;
            element.classList.add("initiated");
            element.querySelector(".item-title").addEventListener("click", e => {
                this.toogle(e.currentTarget);
            });
        });
    }
    toogle(element) {
        let parentNode = element.parentNode;


        if (!parentNode.classList.contains("active")) {
            if (this.element && this.element.dataset.collapseAll == "collapse") {
                this.element.querySelectorAll(".accordion-item .item-title").forEach(element => {
                    this.close(element);
                });
            }
            this.open(element)
        } else {
            this.close(element)
        }
    }
    open(element) {
        if(!element){
            return false;
        }
        let parentNode = element.parentNode;
        let content = parentNode.querySelector(".content");
        parentNode.classList.add("active");
        content.style.transitionDuration = 200 + (parentNode.dataset.contentheight / 2) + "ms";
        content.style.height = parentNode.dataset.contentheight + "px";
        content.classList.add("active");
    }
    close(element) {
        if(!element){
            return false;
        }
        let parentNode = element.parentNode;
        let content = parentNode.querySelector(".content");
        parentNode.classList.remove("active");
        content.style.transitionDuration = "200ms";
        content.style.height = 0;
        content.classList.remove("active");
    }
}