var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li>\r\n              <button type=\"button\" class=\"btn btn-outline-primary\" data-profile-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":14,"column":85},"end":{"line":14,"column":91}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":14,"column":93},"end":{"line":14,"column":101}}}) : helper)))
    + "</button>\r\n          </li>        \r\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"widget trail "
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\" data-id=\""
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-name=\""
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" data-path=\""
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\">\r\n      <h2>"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h2>\r\n      <div class=\"list\" data-trail-id=\""
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depths[1] != null ? lookupProperty(depths[1],"trails") : depths[1])) != null ? lookupProperty(stack1,"content") : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":25,"column":8},"end":{"line":70,"column":17}}})) != null ? stack1 : "")
    + "      </div>\r\n    </div>\r\n\r\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifEquals")||(depth0 && lookupProperty(depth0,"ifEquals"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"trail") : stack1),((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"id") : stack1),{"name":"ifEquals","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":26,"column":12},"end":{"line":69,"column":25}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"content\" data-profiles=\""
    + alias3((lookupProperty(helpers,"tostring")||(depth0 && lookupProperty(depth0,"tostring"))||alias2).call(alias1,((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"profiles") : stack1),{"name":"tostring","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":27,"column":48},"end":{"line":27,"column":77}}}))
    + "\">\r\n                <div class=\"widget dropdown\">\r\n                    <div class=\"dropdown-links\">\r\n                        <ul>\r\n                            <li>\r\n                                <a class=\"copy-url\" data-href=\""
    + alias3((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"trails",{"name":"route","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":32,"column":63},"end":{"line":32,"column":81}}}))
    + "/video-player/"
    + alias3(alias4(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"video_cod") : stack1), depth0))
    + "\">\r\n                                    <i class=\"material-icons-outlined\">insert_link</i>\r\n                                    "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/most_viewed_usecases/copy-link",{"name":"translate","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":34,"column":36},"end":{"line":34,"column":89}}}))
    + "\r\n                                </a>\r\n                            </li>\r\n                        </ul>\r\n                    </div>\r\n                </div>              \r\n\r\n"
    + ((stack1 = (lookupProperty(helpers,"ifEquals")||(depth0 && lookupProperty(depth0,"ifEquals"))||alias2).call(alias1,((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"type") : stack1),"youtube",{"name":"ifEquals","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":41,"column":16},"end":{"line":55,"column":29}}})) != null ? stack1 : "")
    + "                <div class=\"description\">\r\n                    <span class=\"title\">"
    + alias3(alias4(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</span>\r\n                    <div class=\"profiles\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"profiles") : stack1),{"name":"each","hash":{},"fn":container.program(8, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":59,"column":22},"end":{"line":65,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\r\n                </div>\r\n            </div>\r\n";
},"6":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"thumbnail video\" data-type=\""
    + alias2(alias1(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\"\r\n                    data-order = "
    + alias2(alias1(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"order") : stack1), depth0))
    + "\r\n                    data-publico_cod = \""
    + alias2(alias1(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"publico_cod") : stack1), depth0))
    + "\"\r\n                    data-url=\""
    + alias2(alias1(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" \r\n                    data-video_id=\""
    + alias2(alias1(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"video_id") : stack1), depth0))
    + "\" \r\n                      data-title=\""
    + alias2(alias1(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\" data-trail-id=\""
    + alias2(alias1(((stack1 = blockParams[3][0]) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\r\n                    <div class=\"play-btn\">\r\n                      <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"44\" height=\"44\" fill=\"currentColor\" class=\"bi bi-play-fill\" viewBox=\"0 0 16 16\">\r\n                        <path d=\"m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z\"/>\r\n                      </svg>\r\n                    </div>\r\n                  <div class=\"video-thumbnail\" style=\"background-image: url(https://i.ytimg.com/vi_webp/"
    + alias2(alias1(((stack1 = blockParams[2][0]) != null ? lookupProperty(stack1,"video_id") : stack1), depth0))
    + "/default.webp)\"></div>\r\n                </div>\r\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depths[3] != null ? lookupProperty(depths[3],"trails") : depths[3])) != null ? lookupProperty(stack1,"profiles") : stack1),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":60,"column":24},"end":{"line":64,"column":33}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifEquals")||(depth0 && lookupProperty(depth0,"ifEquals"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),blockParams[1][0],(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"ifEquals","hash":{},"fn":container.program(10, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":61,"column":26},"end":{"line":63,"column":39}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"badge rounded-pill text-bg-primary "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":62,"column":76},"end":{"line":62,"column":84}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":62,"column":86},"end":{"line":62,"column":94}}}) : helper)))
    + "</span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Trails Template -->\r\n<div class=\"page trails full\">\r\n\r\n  <div class=\"page-wrapper\">\r\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":21}}}) : helper))) != null ? stack1 : "")
    + "\r\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"side_index") || (depth0 != null ? lookupProperty(depth0,"side_index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"side_index","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":20}}}) : helper))) != null ? stack1 : "")
    + "\r\n\r\n  <h1 class=\"title page-title\">Trilhas</h1>\r\n  <div class=\"widget profile-selector\">\r\n      <h6 class=\"title\">"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/profile-selector/title",{"name":"translate","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":10,"column":24},"end":{"line":10,"column":69}}}))
    + "</h6>\r\n      <ul>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"profiles") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":12,"column":6},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "      </ul>\r\n  </div>\r\n\r\n<div class=\"widget trail-list\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"trails") : depth0)) != null ? lookupProperty(stack1,"trails") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":21,"column":2},"end":{"line":74,"column":11}}})) != null ? stack1 : "")
    + "</div>\r\n\r\n\r\n\r\n\r\n  </div>\r\n</div>";
},"useData":true,"useDepths":true,"useBlockParams":true});