import { Widgets } from "./Widgets";

export class WhatsAppInterface extends Widgets {
    constructor(selector) {
        super(selector)
        this.send_button = this.element.querySelector(".send-button");
        this.user = this.element.querySelector('input[name="user"]');
        this.message = this.element.querySelector('textarea[name="message"]');
        this.user.addEventListener("blur", e => {
            this.preview();
        });
        this.send_button.addEventListener("click", e => {
            this.send_button.classList.add("inactive");
            let pass = true;
            let data = {};
            data.url = this.parent.currentLocation(this.parent.router.root);

            this.element.querySelectorAll(".form-group .form-field").forEach(elmn => {
                if (elmn.value == "" && elmn.attributes.required) {
                    console.log(`${elmn.name} field is required`);
                    pass = false;
                    return;
                }
                data[elmn.name] = elmn.value.trim();
            });
            console.log(data);
            if (pass) {
                this.send(data)
            } else {
                this.send_button.classList.remove("inactive");
            }
        });
    }
    async preview() {
        if (!this.parent.send_content_preview) {
            this.parent.send_content_preview = await this.parent.call("send/preview");
        }

        let message = this.parent.send_content_preview.find(item => {
            return item.type == "WhatsApp";
        }).content;
        message = message.replace("{name}", this.user.value);
        message = message.replace("{link}", this.parent.settings.environment.live + this.parent.currentLocation(this.parent.router.root));

        this.message.value = message;
    }
    send(params) {
        this.parent.loading.start();
        let data = {
            "to": params.to,
            "name" : params.user,
            "link": this.parent.settings.environment.live + params.url,
        };

        this.parent.post(
            this.parent.settings.whatsapp.endpoint,
            (response) => {
                try {
                    window.alert("Mensagem entregue com sucesso");
                    this.parent.loading.stop();
                    this.send_button.classList.remove("inactive");                        
                } catch (error) {
                    console.log(error);
                }
            },
            "POST",
            data,
        );
    }
    status(params) {
        if (params.status) {
            if (params.status == "queued" || params.status == "sending" || params.status == "sent") {
                this.parent.post(
                    this.parent.settings.whatsapp.api + params.uri,
                    (response) => {
                        this.status(response);
                    },
                    "GET",
                    null,
                    this.parent.settings.whatsapp.auth
                );
            }
            if (params.status == "delivered" || params.status == "read") {
                window.alert("Mensagem entregue com sucesso");
                this.parent.loading.stop();
                this.send_button.classList.remove("inactive");
            }
            if (params.status == "undelivered") {
                window.alert("Mensagem não pode ser entregue");
                this.parent.loading.stop();
                this.send_button.classList.remove("inactive");
            }
        } else {
            window.alert("Mensagem não enviada. Por favor revise os dados e tente novamente. ");
            this.parent.loading.stop();
            this.send_button.classList.remove("inactive");
        }
    }
}