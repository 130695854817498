var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"card\">\r\n                    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":23,"column":29},"end":{"line":23,"column":37}}}) : helper)))
    + "f/"
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"category") : depths[1])) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topicPath","hash":{},"data":data,"loc":{"start":{"line":23,"column":60},"end":{"line":23,"column":73}}}) : helper)))
    + "\" class=\"wrapper navigate-to\"\r\n                        data-navigate-to=\"f/"
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"category") : depths[1])) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topicPath","hash":{},"data":data,"loc":{"start":{"line":24,"column":65},"end":{"line":24,"column":78}}}) : helper)))
    + "\">\r\n                        <span class=\"tutorial-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"topicName") || (depth0 != null ? lookupProperty(depth0,"topicName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topicName","hash":{},"data":data,"loc":{"start":{"line":25,"column":53},"end":{"line":25,"column":66}}}) : helper)))
    + "</span>\r\n                        <span class=\"see-usecase\">Ver tutorial</span>\r\n                    </a>\r\n                </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Categories Template -->\r\n<div class=\"page category full\">\r\n    <div class=\"page-header\">\r\n        <div class=\"wrapper\">\r\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\r\n            <h1 class=\"page-title\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\r\n            <div class=\"description\">\r\n                <div class=\"icon-holder\">\r\n                    <i class=\"icon qc-icon "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\" style=\"-webkit-mask-image: url("
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? lookupProperty(stack1,"iconImageUrl") : stack1), depth0))
    + ");\"></i>\r\n                </div>\r\n                <p class=\"text\">\r\n                    "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"category") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\r\n                </p>\r\n            </div>\r\n        </div>\r\n    </div>\r\n    <div class=\"page-wrapper\">\r\n        <h2 class=\"title\">Tutoriais</h2>\r\n        <div class=\"content widget tutorial-list\">\r\n            <ul class=\"panel\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"topics") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":29,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\r\n        </div>\r\n    </div>\r\n</div>\r\n<!-- End Categories Template -->";
},"useData":true,"useDepths":true});