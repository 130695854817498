import { Dropdown } from "./Dropdown";
import { ULPaginated } from "./ULPaginated";

export class Faq extends ULPaginated {
  constructor(selector) {
    super(selector);
  }
  init() {
    var items = this.element
      ? this.element.querySelectorAll(".dropdown")
      : false;
    const _that = this;
    if (items) {
      items.forEach(function (item, key) {
        const name = key+Math.random();
        _that.parent.widgets.register("dropdown" + name, new Dropdown(item));
      });
    }
  }
}
