var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"card "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":13,"column":32},"end":{"line":13,"column":40}}}) : helper)))
    + "\" data-choice=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":13,"column":55},"end":{"line":13,"column":63}}}) : helper)))
    + "\"\r\n                data-brand-path=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"brand_path") : depths[1]), depth0))
    + "\"\r\n                data-device-path=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"device_path") : depths[1]), depth0))
    + "\"\r\n                data-category-path=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"category_path") : depths[1]), depth0))
    + "\"\r\n                data-tutorial-path=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"tutorial_path") : depths[1]), depth0))
    + "\"\r\n                >"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":18,"column":17},"end":{"line":18,"column":25}}}) : helper)))
    + "</li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Usecase Template -->\r\n<div class=\"page usecase\">\r\n    <div class=\"wrapper\">\r\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\r\n        <h1 class=\"page-title\">"
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"usecase") : depth0)) != null ? lookupProperty(stack1,"topicName") : stack1), depth0))
    + "</h1>\r\n\r\n        <div class=\"widget choices\">\r\n            <p class=\"\">\r\n                <i class=\"material-icons\">warning</i>\r\n                    "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/choices/choose-configuration",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":71}}}))
    + " </p>\r\n            <ul class=\"panel\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"usecase") : depth0)) != null ? lookupProperty(stack1,"choices") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":19,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\r\n        </div>\r\n    </div>\r\n</div>";
},"useData":true,"useDepths":true});