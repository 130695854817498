import { Widgets } from "./Widgets";

export class SendMail extends Widgets {
    constructor(selector) {
        super(selector)
        this.send_button = this.element.querySelector(".send-button");
        this.email = this.element.querySelector('input[name="to"]');
        this.user = this.element.querySelector('input[name="user"]');
        this.message = this.element.querySelector('textarea[name="message"]');
        this.user.addEventListener("blur", e => {
            this.preview();
        });
        this.email.addEventListener("keyup", e => {
            let valid = this.email.value.toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            // console.log(valid);
            if (valid) {
                this.send_button.classList.remove("inactive");
            } else {
                this.send_button.classList.add("inactive");
            }
        });
        this.element.querySelector('input[name="subject"]').value = document.querySelector(".page-title").innerText;
        this.send_button.addEventListener("click", e => {
            this.send_button.classList.add("inactive");
            let pass = true;
            let data = {};
            data.url = this.parent.currentLocation(this.parent.router.root);

            this.element.querySelectorAll(".form-group .form-field").forEach(elmn => {
                if (elmn.value == "" && elmn.attributes.required) {
                    console.log(`${elmn.name} field is required`);
                    pass = false;
                    return;
                }
                data[elmn.name] = elmn.value.trim();
            });
            if (pass) {
                this.send(data)
            } else {
                this.send_button.classList.remove("inactive");
            }
        });
    }
    async preview() {
        if (!this.parent.send_content_preview) {
            this.parent.send_content_preview = await this.parent.call("send/preview");
        }

        let message = this.parent.send_content_preview.find(item => {
            return item.type == "SMS";
        }).content;
        if (message) {
            message = message.replace("{name}", this.user.value);
            message = message.replace("{link}", this.parent.settings.environment.live + this.parent.currentLocation(this.parent.router.root));
            this.message.value = message;
        }
    }

    send(params) {
        let data = {
            "to": params.to,
            "from": "no-reply@qelplatam.com",
            "subject": params.subject,
            "content": this.message.value.trim()
        }
        this.parent.post(
            this.parent.settings.sendmail.endpoint,
            (response) => {
                window.alert("Mensagem enviada!");
            },
            "POST",
            data
        );

    }

}