import { Widgets } from "./Widgets";

export class Dropdown extends Widgets {
  constructor(target) {
    super(null);
    this.element = target;
  }

  init() {
    this.textarea = document.createElement("textarea");
    this.textarea.style.position = "absolute";
    this.textarea.style.top = "0px";
    this.textarea.style.left = "0px";
    this.textarea.style.width = "0px";
    this.textarea.style.height = "0px";
    this.textarea.style.border = "none";
    this.textarea.style.opacity = "0";
    this.element.append(this.textarea);
    this.setEvents();
  }

  setEvents() {
    this.element.addEventListener("click", (e) => {
      var item = e.target.querySelector(".dropdown-links");

      if (!item) {
        return false;
      }

      item.querySelectorAll("a.copy-url[data-href]").forEach((element) => {
        element.addEventListener("click", (e) => {
          this.copy(e.currentTarget);
        });
      });

      this.toggleDropdown(item);
      item.addEventListener("mouseleave", (e)=>{
        e.currentTarget.classList.remove("active");
      })
    });
  }

  copy(link) {
    if (!this.textarea) {
      return;
    }
    let live = this.parent.settings.environment.live;
    let url = live + link.dataset.href;

    
    if (!navigator.clipboard) {
      this.textarea.value = url;
      this.textarea.focus();
      this.textarea.select();
      document.execCommand("copy");
      console.log("URL copied with execCommand");
    } else {
      navigator.clipboard.writeText(url).then(() => {
        console.log("URL copied with clipboard", url);
      });
    }

    this.closeDropdown(link);
  }

  toggleDropdown(item) {
    if (!item.classList.contains("active")) {
      item.classList.add("active");
    } else {
      item.classList.remove("active");
    }
  }

  closeDropdown(item) {
    if (item) {
      item.parentNode.parentNode.parentNode.classList.remove("active");
    }
  }
}
