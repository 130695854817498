import * as msal from "@azure/msal-browser";

export class MSAL {
  constructor() {
    this.response;
    this.username = "";
    this.msalConfig = {
      auth: {},
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case msal.LogLevel.Error:
                console.error(message);
                return;
              case msal.LogLevel.Info:
                console.info(message);
                return;
              case msal.LogLevel.Verbose:
                console.debug(message);
                return;
              case msal.LogLevel.Warning:
                console.warn(message);
                return;
            }
          },
        },
      },
    };
    this.loginRequest = {
      scopes: ["User.Read", "group.Read.All"],
    };
  }
  async init() {
    this.msalInstance = new msal.PublicClientApplication(this.msalConfig);
    this.currentAccounts = this.msalInstance.getAllAccounts();

    await this.msalInstance
      .handleRedirectPromise()
      .then((response) => {
        this.response = response;
        this.handleResponse(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  handleResponse(response) {
    console.log(response);
    this.selectAccount();
  }
  selectAccount() {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */

    const currentAccounts = this.msalInstance.getAllAccounts();
    console.log(currentAccounts);
    // console.log(this.username);
    if (currentAccounts.length === 0) {
      this.signIn();
      return;
    } else if (currentAccounts.length > 1) {
      console.warn("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {
      this.account = currentAccounts[0];
      this.username = this.account.username;

      // this.groups = this.account.idTokenClaims?.groups;
      this.claims = this.account.idTokenClaims?.aud;
      console.log(this.account);
      // console.info(this.groups);

      if (this.claims == this.msalConfig.auth.clientId) {
      // if (this.groups?.find(item=>{
      //   return item == 'a476e637-2548-4f41-906b-68ae75066f50';
      // })) {
      //   console.log('a476e637-2548-4f41-906b-68ae75066f50');
        this.welcomeEvent();
      } else {
        const warning = document.createElement("p");
        warning.classList.add("warn");
        warning.innerHTML = 'Houve um problema na autenticação. Por favor entre em contato com seu administrador.<br>';
        const logout = document.createElement("button");
        logout.addEventListener("click", e=>{
          this.signOut();
        })
        logout.innerHTML = "Sair";
        warning.appendChild(logout);

        document.body.appendChild(warning);
      }
    }
  }
  welcomeEvent() {
    console.log("MSAL_USER_LOGGED_IN");
    let e = new CustomEvent("MSAL_USER_LOGGED_IN");
    e.username = this.username;
    document.dispatchEvent(e);
  }
  signIn() {
    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */

    this.msalInstance.loginRedirect(this.loginRequest);
  }
  signOut() {
    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */

    const logoutRequest = {
      account: this.msalInstance.getAccountByUsername(this.username),
      postLogoutRedirectUri: this.msalConfig.auth.redirectUri,
    };

    this.msalInstance.logoutRedirect(logoutRequest);
  }
}
