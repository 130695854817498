var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li class=\"card "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":5,"column":22},"end":{"line":5,"column":30}}}) : helper)))
    + "\">\r\n        <a\r\n          href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":24}}}) : helper)))
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"application",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":7,"column":47}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":7,"column":48},"end":{"line":7,"column":56}}}) : helper)))
    + "\"\r\n          data-navigate-to=\""
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"application",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":51}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":8,"column":52},"end":{"line":8,"column":60}}}) : helper)))
    + "\"\r\n          class=\"wrapper\"\r\n        >\r\n          <div class=\"image\">\r\n            <img\r\n              src=\""
    + alias4((lookupProperty(helpers,"resizeImage")||(depth0 && lookupProperty(depth0,"resizeImage"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"softwareImage") : depth0)) != null ? lookupProperty(stack1,"imageURL") : stack1),"56x56",{"name":"resizeImage","hash":{},"data":data,"loc":{"start":{"line":13,"column":19},"end":{"line":13,"column":65}}}))
    + "\"\r\n              alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":14,"column":19},"end":{"line":14,"column":27}}}) : helper)))
    + "\"\r\n            />\r\n          </div>\r\n          <div class=\"details\">\r\n            <p class=\"type\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</p>\r\n            <h3 class=\"title\">\r\n              "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":20,"column":14},"end":{"line":20,"column":22}}}) : helper)))
    + "\r\n            </h3>\r\n          </div>\r\n        </a>\r\n      </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Most Viewed Apps Template -->\r\n<div class=\"widget most_viewed_apps\">\r\n  <ul class=\"panel\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"top_apps") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":25,"column":13}}})) != null ? stack1 : "")
    + "  </ul>\r\n</div>\r\n<!-- End Most Viewed Apps Template -->";
},"useData":true});