var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"item-tab "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":12,"column":36},"end":{"line":12,"column":44}}}) : helper)))
    + "\" data-path=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":12,"column":57},"end":{"line":12,"column":65}}}) : helper)))
    + "\">\r\n                    <span class=\"item-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":13,"column":44},"end":{"line":13,"column":52}}}) : helper)))
    + "</span>\r\n                </li>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"content-tab tab-"
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":19,"column":43},"end":{"line":19,"column":51}}}) : helper)))
    + " "
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"path") : depth0), depth0))
    + "\">\r\n                    "
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"html") : depth0), depth0)) != null ? stack1 : "")
    + "\r\n                </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Device Template -->\r\n<div class=\"page device app\">\r\n    <div class=\"wrapper\">\r\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\r\n        <h1 class=\"page-title\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"app") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\r\n        <div class=\"widget tabs\">\r\n            <h3 class=\"tabs-title\">\r\n                "
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"page/app/select_os",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":50}}}))
    + "\r\n            </h3>\r\n            <ul class=\"tabs-head\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"app") : depth0)) != null ? lookupProperty(stack1,"os") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":15,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\r\n            <ul class=\"tabs-content\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"topic_by_os") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":22,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\r\n        </div>\r\n    </div>\r\n    <div class=\"aside-content\">\r\n        <div class=\"device-details\">\r\n            <div class=\"device-images\">\r\n                <div class=\"widget active-device-image\">\r\n                    <div class=\"image\">\r\n                        <img class=\"startScreen\" src=\""
    + alias4((lookupProperty(helpers,"resizeImage")||(depth0 && lookupProperty(depth0,"resizeImage"))||alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"app") : depth0)) != null ? lookupProperty(stack1,"softwareImage") : stack1)) != null ? lookupProperty(stack1,"imageURL") : stack1),"240x250",{"name":"resizeImage","hash":{},"data":data,"loc":{"start":{"line":31,"column":54},"end":{"line":31,"column":106}}}))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":31,"column":113},"end":{"line":31,"column":121}}}) : helper)))
    + "\">\r\n                    </div>\r\n                </div>\r\n\r\n\r\n            </div>\r\n            <div class=\"buttons\">\r\n                <div class=\"simple-button borded manual\">\r\n                    <p>\r\n                        <i class=\"material-icons-outlined\">description</i> "
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"page/app/manual",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":40,"column":75},"end":{"line":40,"column":106}}}))
    + "\r\n                    </p>\r\n                </div>\r\n            </div>\r\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"most_viewed_usecases") || (depth0 != null ? lookupProperty(depth0,"most_viewed_usecases") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"most_viewed_usecases","hash":{},"data":data,"loc":{"start":{"line":44,"column":12},"end":{"line":44,"column":38}}}) : helper))) != null ? stack1 : "")
    + "\r\n        </div>\r\n    </div>\r\n</div>\r\n<!-- End Device Template -->";
},"useData":true});