var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li class=\"card\">\r\n        <a\r\n          href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":24}}}) : helper)))
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"trails",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":8,"column":24},"end":{"line":8,"column":42}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":8,"column":43},"end":{"line":8,"column":51}}}) : helper)))
    + "\"\r\n          class=\"wrapper\"\r\n          data-navigate-to=\""
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"trails",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":10,"column":28},"end":{"line":10,"column":46}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":10,"column":47},"end":{"line":10,"column":55}}}) : helper)))
    + "\">\r\n          <div class=\"details\">\r\n            <h3 class=\"title\">\r\n              "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":13,"column":14},"end":{"line":13,"column":22}}}) : helper)))
    + "\r\n            </h3>\r\n          </div>\r\n          <div class=\"arrows\">\r\n            <i class=\"material-icons\">chevron_right</i>\r\n          </div>\r\n        </a>\r\n      </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Trails Template -->\r\n<div class=\"widget trails\">\r\n  <h2>"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"widget/trails/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":6},"end":{"line":3,"column":41}}}))
    + "</h2>\r\n  <ul class=\"panel\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"content") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":21,"column":13}}})) != null ? stack1 : "")
    + "  </ul>\r\n\r\n</div>\r\n<!-- End Trails Template -->";
},"useData":true});