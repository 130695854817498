var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <!-- Wrapped Start Screen and Device Image-->\r\n\r\n            <div class=\"image\">\r\n              <div class=\"image-wrapper\">\r\n                <img\r\n                  class=\"startScreen\"\r\n                  src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"coverImageUrl") || (depth0 != null ? lookupProperty(depth0,"coverImageUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"coverImageUrl","hash":{},"data":data,"loc":{"start":{"line":24,"column":23},"end":{"line":24,"column":40}}}) : helper)))
    + "\"\r\n                  alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":25,"column":23},"end":{"line":25,"column":31}}}) : helper)))
    + "\"\r\n                />\r\n              </div>\r\n            </div>\r\n            <!-- Wrapped Start Screen and Device Image-->\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"simple-button specs\">\r\n            <p>\r\n              <i class=\"material-icons show\">expand_more</i>\r\n              <i class=\"material-icons hide\">close</i>\r\n              "
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"page/device/specs",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":40,"column":14},"end":{"line":40,"column":47}}}))
    + "\r\n            </p>\r\n          </div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"simple-button borded manual\">\r\n            <p>\r\n              <a href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"manual_link") || (depth0 != null ? lookupProperty(depth0,"manual_link") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"manual_link","hash":{},"data":data,"loc":{"start":{"line":47,"column":23},"end":{"line":47,"column":38}}}) : helper)))
    + "\" target=\"_blank\">\r\n                <i class=\"material-icons-outlined\">description</i>\r\n                "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"page/device/manual",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":49,"column":50}}}))
    + "\r\n              </a>\r\n            </p>\r\n          </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Device Template -->\r\n<div class=\"page device\">\r\n  "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"specs") || (depth0 != null ? lookupProperty(depth0,"specs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"specs","hash":{},"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":13}}}) : helper))) != null ? stack1 : "")
    + "\r\n\r\n  "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":5,"column":19}}}) : helper))) != null ? stack1 : "")
    + "\r\n  <div class=\"page-title-wrapper\">\r\n    <h1 class=\"page-title\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\r\n  </div>\r\n\r\n  "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"categories_tutorials") || (depth0 != null ? lookupProperty(depth0,"categories_tutorials") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"categories_tutorials","hash":{},"data":data,"loc":{"start":{"line":10,"column":2},"end":{"line":10,"column":28}}}) : helper))) != null ? stack1 : "")
    + "\r\n\r\n  <div class=\"aside-content\">\r\n    <div class=\"device-details\">\r\n      <div class=\"device-images\">\r\n        <div class=\"widget active-device-image\">\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"device") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":10},"end":{"line":30,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n\r\n      </div>\r\n      <div class=\"buttons\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"specs") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":43,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"manual_link") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":8},"end":{"line":53,"column":15}}})) != null ? stack1 : "")
    + "      </div>\r\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"most_viewed_usecases") || (depth0 != null ? lookupProperty(depth0,"most_viewed_usecases") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"most_viewed_usecases","hash":{},"data":data,"loc":{"start":{"line":55,"column":6},"end":{"line":55,"column":32}}}) : helper))) != null ? stack1 : "")
    + "\r\n    </div>\r\n  </div>\r\n</div>\r\n<!-- End Device Template -->";
},"useData":true});