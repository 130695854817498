var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"card "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":4,"column":24},"end":{"line":4,"column":32}}}) : helper)))
    + "\">\r\n            <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":5,"column":21},"end":{"line":5,"column":29}}}) : helper)))
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"manufacturers",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":5,"column":29},"end":{"line":5,"column":54}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":5,"column":55},"end":{"line":5,"column":63}}}) : helper)))
    + "\" data-navigate-to=\""
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"manufacturers",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":5,"column":83},"end":{"line":5,"column":108}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":5,"column":109},"end":{"line":5,"column":117}}}) : helper)))
    + "\" class=\"wrapper\">\r\n                <div class=\"image\">\r\n                    <img src=\""
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"assets") : depths[1]), depth0))
    + "manufacturers/70x70/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":7,"column":63},"end":{"line":7,"column":71}}}) : helper)))
    + ".png\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":7,"column":82},"end":{"line":7,"column":90}}}) : helper)))
    + "\">\r\n                </div>\r\n                <div class=\"details\">\r\n                    <h3 class=\"title\">\r\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":11,"column":32}}}) : helper)))
    + "\r\n                    </h3>\r\n                    <p class=\"total-devices\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"deviceNumber") || (depth0 != null ? lookupProperty(depth0,"deviceNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"deviceNumber","hash":{},"data":data,"loc":{"start":{"line":13,"column":45},"end":{"line":13,"column":61}}}) : helper)))
    + " "
    + alias4((lookupProperty(helpers,"pluralize")||(depth0 && lookupProperty(depth0,"pluralize"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"deviceNumber") : depth0),"widget/manufacturer-list/total-devices",{"name":"pluralize","hash":{},"data":data,"loc":{"start":{"line":13,"column":62},"end":{"line":13,"column":129}}}))
    + "</p>\r\n                </div>\r\n                <div class=\"icon-holder\">\r\n                    <i class=\"material-icons\">chevron_right</i>\r\n                </div>\r\n            </a>\r\n        </li>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget manufacturer-list\">\r\n    <ul class=\"panel\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"manufacturers") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":20,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\r\n</div>";
},"useData":true,"useDepths":true});