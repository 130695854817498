import "../../../qelpcare-call-center-application/src/main.scss";
import "./defaults.scss";
import "./ultragaz.scss";

import { QelpCare } from "../../../qelpcare-call-center-application/src/QelpCare";
import { TrailList } from "./widgets/TrailList";
import { ProfileSelector } from "./widgets/ProfileSelector";
import { MostViewedUsecases } from "../../../qelpcare-call-center-application/src/widgets/MostViewedUsecases";
import { SideIndex } from "../../../qelpcare-call-center-application/src/widgets/SideIndex";
import { MSAL } from "./msal";

require("./helpers");

class App extends QelpCare {
  constructor() {
    super(".application");
    this.pages.device = async (brand_path, device_path) => {
      let brand = await this.getManufacturer(brand_path);
      let device = await this.call(`devices/${device_path}`);
      let topics = await this.call(`topics/device/${device.id}`);
      this.html.head(this.translate("html/title") + " - " + device.name);
      this.render(
        this.settings.templates.pages.device({
          brand: brand,
          device: device,
          most_viewed_usecases:
            this.settings.templates.widgets.most_viewed_usecases({
              content: topics,
              perpage: 0,
            }),
          breadcrumbs: this.breadcrumbs([
            {
              name: this.translate("breadcrumbs/home"),
              path: "/",
            },
            {
              name: this.translate("breadcrumbs/manufacturers"),
              path: `/${this.settings.routes.manufacturers}`,
            },
            {
              name: brand.name,
              path: `/${this.settings.routes.manufacturers}/${brand.path}`,
            },
            {
              name: device.name,
            },
          ]),
        }),
        () => {
          this.initiate.breadcrumbs();
          this.widgets.register(
            "most_viewed_usecases",
            new MostViewedUsecases("most_viewed_usecases")
          );
        }
      );
    };
    this.pages.trails = async (trail_path) => {
      let trails = require("./assets/trails/trails.json");
      let side_index_itens = {};

      trails.content.sort((a, b) => {
        return a.order - b.order;
      });

      trails.trails.forEach((item) => {
        side_index_itens[item.path] = item;
      });

      this.render(
        this.settings.templates.pages.trails({
          side_index: this.settings.templates.widgets.trail_side_index({
            list: side_index_itens,
          }),
          profiles: trails.profiles,
          trails: trails,
          breadcrumbs: this.breadcrumbs([
            {
              name: this.translate("breadcrumbs/home"),
              path: "/",
            },
            {
              name: "Trilhas",
            },
          ]),
        }),
        () => {
          this.element
            .querySelectorAll(".widget.trail-list .trail")
            ?.forEach((item) => {
              this.widgets.register(
                "trailist" + item.dataset.id,
                new TrailList(item)
              );
            });
          let profile_selector = this.widgets.register(
            "profile-selector",
            new ProfileSelector("profile-selector")
          );
          profile_selector.element.addEventListener("PROFILE_SELECTED", (e) => {
            const button = e.target;
            const profile_id = e.detail.profile_id;
            const active = e.detail.active;
            const list = document.querySelectorAll(".trail-list .content");
            list.forEach((item) => {
              if (!item.dataset.profiles.match(profile_id) && active) {
                item.classList.add("unselected");
              } else {
                item.classList.remove("unselected");
              }
            });
          });
          let selected = this.element.querySelector(
            `.trail[data-path=${trail_path}] h2`
          );
          this.widgets.register("side_index", new SideIndex("side-index"));
          setTimeout(() => {
            selected.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }, 300);
        }
      );
    };
  }
}

const app = new App();

app.config({
  name: "Universidade Ultragaz",
  locale: "pt-br",
  localization: require("./assets/json/localization.json"),
  assets: "https://assets.qelplatam.com/",
  environment: {
    live: "https://mapultragaz.com.br/universidade-ultragaz#",
  },
  api: [
    {
      test: /localhost:9010/,
      set: "https://middleware-ultragaz-desenv.qelplatam.com/api-csr/",
    },
    {
      test: /csr-ultragaz-desenv.qelplatam.com/,
      set: "https://middleware-ultragaz-desenv.qelplatam.com/api-csr/",
    },
    {
      test: /csr-ultragaz-accept.qelplatam.com/,
      set: "https://middleware-ultragaz-accept.qelplatam.com/api-csr/",
    },
    {
      test: /csr-ultragaz-prod.qelplatam.com/,
      set: "https://middleware-ultragaz-prod.qelplatam.com/api-csr/",
    },
    {
      test: /www.universidadeultragaz.com.br/,
      set: "https://middleware-ultragaz-prod.qelplatam.com/api-csr/",
    },
  ],
  root: [
    { test: /localhost:9010/, set: "//localhost:9010/" },
    {
      test: /www.universidadeultragaz.com.br/,
      set: "//www.universidadeultragaz.com.br/",
    },
    {
      test: /csr-ultragaz-prod.qelplatam.com/,
      set: "//csr-ultragaz-prod.qelplatam.com/",
    },
    {
      test: /csr-ultragaz-desenv.qelplatam.com/,
      set: "//csr-ultragaz-desenv.qelplatam.com/",
    },
    {
      test: /csr-ultragaz-accept.qelplatam.com/,
      set: "//csr-ultragaz-accept.qelplatam.com/",
    },
  ],
  routes: {
    trails: "trilhas",
    manufacturers: "fabricantes",
    devices: "temas",
  },
  wrappers: [
    "smartphone",
    "smartwatch",
    "tablet",
    "fixed-phone",
    "telefone-fixo",
  ],
  smssender: {
    endpoint: "https://api-demo.qelplatam.com/api-csr/send/sms",
  },
  whatsapp: {
    endpoint: "https://api-demo.qelplatam.com/api-csr/send/whatsapp",
  },
  sendmail: {
    endpoint: "https://api-demo.qelplatam.com/api-csr/send/email",
  },
  contents: {
    home: {
      main_content: [
        {
          widget: "device_types",
          endpoint: "devices/popular",
        },
        {
          widget: "trails",
          endpoint: function () {
            return require("./assets/trails/trails.json")?.trails || [];
          },
        },
        {
          widget: "faq",
          endpoint: "topics/device/3/with-faq",
        },
      ],
      aside_panel: [
        {
          widget: "most_viewed_usecases",
          endpoint: "topics/popular",
        },
      ],
    },
    trails: {
      main_content: [
        {
          widget: "trails",
          endpoint: function () {
            return require("./assets/trails/trails.json")?.trails || [];
          },
        },
      ],
    },
  },
  templates: {
    pages: {
      home: require("../../../qelpcare-call-center-application/src/templates/pages/home.handlebars"),
      softwares: require("../../../qelpcare-call-center-application/src/templates/pages/softwares.handlebars"),
      smartphone: require("../../../qelpcare-call-center-application/src/templates/pages/smartphone.handlebars"),
      app: require("../../../qelpcare-call-center-application/src/templates/pages/app.handlebars"),
      manufacturers: require("../../../qelpcare-call-center-application/src/templates/pages/manufacturers.handlebars"),
      device_type: require("../../../qelpcare-call-center-application/src/templates/pages/device_type.handlebars"),
      brand: require("../../../qelpcare-call-center-application/src/templates/pages/brand.handlebars"),
      device: require("../../../qelpcare-call-center-application/src/templates/pages/device.handlebars"),
      usecase: require("/src/templates/pages/usecase.handlebars"),
      categories: require("../../../qelpcare-call-center-application/src/templates/pages/categories.handlebars"),
      category: require("../../../qelpcare-call-center-application/src/templates/pages/category.handlebars"),
      tv: require("../../../qelpcare-call-center-application/src/templates/pages/tv.handlebars"),
      dts: require("../../../qelpcare-call-center-application/src/templates/pages/dts.handlebars"),
      trails: require("/src/templates/pages/trails.handlebars"),
    },
    widgets: {
      breadcrumbs: require("../../../qelpcare-call-center-application/src/templates/widgets/breadcrumbs.handlebars"),
      choices: require("../../../qelpcare-call-center-application/src/templates/widgets/choices.handlebars"),
      steps_navigation: require("../../../qelpcare-call-center-application/src/templates/widgets/steps_navigation.handlebars"),
      share_topic: require("../../../qelpcare-call-center-application/src/templates/widgets/share_topic.handlebars"),
      side_index: require("/src/templates/widgets/side_index.handlebars"),
      trail_side_index: require("/src/templates/widgets/trail_side_index.handlebars"),
      most_viewed_devices: require("../../../qelpcare-call-center-application/src/templates/widgets/most_viewed_devices.handlebars"),
      most_viewed_apps: require("../../../qelpcare-call-center-application/src/templates/widgets/most_viewed_apps.handlebars"),
      most_viewed_usecases: require("../../../qelpcare-call-center-application/src/templates/widgets/most_viewed_usecases.handlebars"),
      device_types: require("/src/templates/widgets/device_types.handlebars"),
      manufacturers: require("../../../qelpcare-call-center-application/src/templates/widgets/manufacturers.handlebars"),
      manufacturer_list: require("../../../qelpcare-call-center-application/src/templates/widgets/manufacturer_list.handlebars"),
      categories: require("../../../qelpcare-call-center-application/src/templates/widgets/categories.handlebars"),
      devices_by_brand: require("../../../qelpcare-call-center-application/src/templates/widgets/devices_by_brand_accordion.handlebars"),
      dts_devices_by_brand: require("../../../qelpcare-call-center-application/src/templates/widgets/dts_devices_by_brand.handlebars"),
      dts_software_by_os: require("../../../qelpcare-call-center-application/src/templates/widgets/dts_software_by_os.handlebars"),
      decode_by_controllers: require("../../../qelpcare-call-center-application/src/templates/widgets/decode_by_controllers.handlebars"),
      software_by_os: require("../../../qelpcare-call-center-application/src/templates/widgets/software_by_os.handlebars"),
      categories_tutorials: require("../../../qelpcare-call-center-application/src/templates/widgets/categories_tutorials.handlebars"),
      categories_tutorials_app: require("../../../qelpcare-call-center-application/src/templates/widgets/categories_tutorials_app.handlebars"),
      apps: require("../../../qelpcare-call-center-application/src/templates/widgets/apps.handlebars"),
      card_filter: require("../../../qelpcare-call-center-application/src/templates/widgets/card_filter.handlebars"),
      trails: require("/src/templates/widgets/trails.handlebars"),
      faq: require("/src/templates/widgets/faq.handlebars"),
    },
  },
});

const msal = new MSAL();

/*
 *  Prod Ultragaz
 */
let clientId = "8000f98a-a22f-43c0-88c5-1ce86213d59e";
let authority = "72b5f416-8f41-4c88-a6a0-bb4b91383888";

/*
 *  HML Ultragaz
 */
if (/csr-ultragaz-accept.qelplatam.com/.test(window.location.toString())) {
  clientId = "e6cb7331-58f1-47f9-bf19-035c51102605";
  authority = "72b5f416-8f41-4c88-a6a0-bb4b91383888";
}

/*
*  Dev Sitel
*/
if (/csr-ultragaz-desenv.qelplatam.com|localhost:9010/.test(window.location.toString())) {
  clientId = "7e6d9a21-5c82-4780-bba4-a2dcd832637c";
  authority = "5cb28473-935c-4fc5-98b6-82e295670fae";
}

let redirectUri;

for (var i = 0; i < app.settings.root.length; i++) {
  console.log(app.settings.root[i].test);
  if (app.settings.root[i].test.test(window.location.toString())) {
    redirectUri = app.settings.root[i].set;
  }
}

msal.msalConfig.auth = {
  clientId: clientId,
  authority: `https://login.microsoftonline.com/${authority}`,
  redirectUri: window.location.protocol + redirectUri,
};
document.addEventListener("MSAL_USER_LOGGED_IN", (e) => {
  app.ready(() => {
    app.router.add(
      `/${app.settings.routes.trails}/(:any)`,
      (trail_id) => {
        app.loading.start();
        app.pages.trails(trail_id);
      }
    );
  });
  const logged_as = document.createElement("div");
  const username = document.createElement("span");
  const signout = document.createElement("span");
  signout.classList.add("signout");
  username.innerHTML = `Olá ${e.username}.`;
  signout.innerHTML = ` Sair.`;
  logged_as.classList.add("logged_as");
  logged_as.append(username);
  logged_as.append(signout);
  signout.addEventListener("click", (e) => {
    msal.signOut();
  });
  document.body.prepend(logged_as);
  document.querySelector(".qelpcare.topper").style.display = "block";
  console.log(e);
});

document.querySelector(".qelpcare.topper").style.display = "none";

msal.init();
