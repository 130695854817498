var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget card-filter\">\r\n  <label>"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/card_filter/label",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":9},"end":{"line":2,"column":49}}}))
    + "</label>\r\n  <input type=\"text\" class=\"card-filter\" placeholder=\""
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/card_filter/placeholder",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":54},"end":{"line":3,"column":100}}}))
    + "\" />\r\n</div>";
},"useData":true});