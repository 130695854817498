var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget share-topic\">\r\n    <div class=\"hide-desktop share-topic-back\">\r\n        <i class=\"material-icons arrow_back\">arrow_back</i>\r\n    </div>\r\n\r\n    <h3 class=\"widget title copy-link\">\r\n        <i class=\"material-icons-outlined file_copy\">file_copy</i>\r\n        <i class=\"material-icons check_circle\">check_circle</i>\r\n        "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/copy-link",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":52}}}))
    + "\r\n    </h3>\r\n\r\n    <div class=\"widget tabs\">\r\n        <h3 class=\"tabs-title\">\r\n            Enviar tutorial\r\n        </h3>\r\n        <ul class=\"tabs-head\">\r\n            <li class=\"item-tab active sms-sender\" data-path=\"sms-sender\">\r\n                <i class=\"material-icons-outlined\">textsms</i>\r\n                SMS\r\n            </li>\r\n            <li class=\"item-tab whatsapp-interface\" data-path=\"whatsapp-interface\">\r\n                <i class=\"material-icons-outlined\">textsms</i>\r\n                Whatsapp\r\n            </li>\r\n            <li class=\"item-tab send-email\" data-path=\"send-email\">\r\n                <i class=\"material-icons-outlined\">email</i>\r\n                Email\r\n            </li>\r\n        </ul>\r\n        <ul class=\"tabs-content\">\r\n            <li class=\"content-tab sms-sender active\">\r\n                <div class=\"widget send-sms-form\">\r\n                    <div class=\"form-group\">\r\n                        <label for=\"to\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/phone",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":34,"column":40},"end":{"line":34,"column":80}}}))
    + "</label>\r\n                        <input class=\"form-field\" required type=\"text\" name=\"to\" placeholder=\"\" value=\"\">\r\n                        <label for=\"user\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/name",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":36,"column":42},"end":{"line":36,"column":81}}}))
    + "</label>\r\n                        <input class=\"form-field\" type=\"text\" required name=\"user\" placeholder=\""
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/name_placeholder",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":96},"end":{"line":37,"column":148}}}))
    + "\" value=\"\">\r\n                        <label for=\"message\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/message",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":38,"column":45},"end":{"line":38,"column":87}}}))
    + "</label>\r\n                        <textarea class=\"form-field\" readonly name=\"message\"></textarea>\r\n                    </div>\r\n                    <button class=\"send-sms-button\">\r\n                        <i class=\"material-icons-outlined\">send</i>\r\n                        "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/send",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":43,"column":24},"end":{"line":43,"column":63}}}))
    + "\r\n                    </button>\r\n                </div>\r\n            </li>\r\n            <li class=\"content-tab whatsapp-interface\">\r\n                <div class=\"widget whatsapp-interface\">\r\n                    <div class=\"form-group\">\r\n                        <label for=\"to\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/whatsapp",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":50,"column":40},"end":{"line":50,"column":83}}}))
    + "</label>\r\n                        <input class=\"form-field\" required type=\"text\" name=\"to\" placeholder=\"\" value=>\r\n                        <label for=\"user\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/name",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":52,"column":42},"end":{"line":52,"column":81}}}))
    + "</label>\r\n                        <input class=\"form-field\" type=\"text\" required name=\"user\" placeholder=\""
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/name_placeholder",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":53,"column":96},"end":{"line":53,"column":148}}}))
    + "\" value=\"\">\r\n                        <label for=\"message\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/message",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":54,"column":45},"end":{"line":54,"column":87}}}))
    + "</label>\r\n                        <textarea class=\"form-field\" readonly name=\"message\"></textarea>\r\n                    </div>\r\n                    <button class=\"send-sms-button send-button\">\r\n                        <i class=\"material-icons-outlined\">send</i>\r\n                        "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/send",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":59,"column":24},"end":{"line":59,"column":63}}}))
    + "\r\n                    </button>\r\n                </div>\r\n            </li>\r\n            <li class=\"content-tab send-email\">\r\n                <div class=\"widget send-email\">\r\n                    <div class=\"form-group\">\r\n                        <label for=\"to\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/email",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":66,"column":40},"end":{"line":66,"column":80}}}))
    + "</label>\r\n                        <input class=\"form-field\" required type=\"text\" name=\"to\" placeholder=\"\" value=\"\">\r\n                        <label for=\"user\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/name",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":68,"column":42},"end":{"line":68,"column":81}}}))
    + "</label>\r\n                        <input class=\"form-field\" type=\"text\" required name=\"user\" placeholder=\""
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/name_placeholder",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":69,"column":96},"end":{"line":69,"column":148}}}))
    + "\" value=\"\">\r\n                        <label for=\"subject\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/subject",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":70,"column":45},"end":{"line":70,"column":87}}}))
    + "</label>\r\n                        <input class=\"form-field\" type=\"text\" required name=\"subject\" placeholder=\"Assunto do email\" value=\"\">\r\n                        <label for=\"message\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/message",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":72,"column":45},"end":{"line":72,"column":87}}}))
    + "</label>\r\n                        <textarea class=\"form-field\" required name=\"message\"></textarea>\r\n                    </div>\r\n                    <button class=\"send-sms-button send-button\">\r\n                        <i class=\"material-icons-outlined\">send</i>\r\n                        "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/send",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":77,"column":24},"end":{"line":77,"column":63}}}))
    + "\r\n                    </button>\r\n                </div>\r\n            </li>\r\n        </ul>\r\n    </div>\r\n\r\n</div>";
},"useData":true});