var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"usecase-step step-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":21,"column":42},"end":{"line":21,"column":52}}}) : helper)))
    + "\" data-step=\""
    + alias4((lookupProperty(helpers,"math")||(depth0 && lookupProperty(depth0,"math"))||alias2).call(alias1,(data && lookupProperty(data,"index")),"+",1,{"name":"math","hash":{},"data":data,"loc":{"start":{"line":21,"column":65},"end":{"line":21,"column":86}}}))
    + "\" animation=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"animation") || (depth0 != null ? lookupProperty(depth0,"animation") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"animation","hash":{},"data":data,"loc":{"start":{"line":21,"column":99},"end":{"line":21,"column":112}}}) : helper)))
    + "\">\r\n\r\n                <div class=\"content\">\r\n                    <div class=\"content-wrapper\">\r\n                        <div class=\"step-number\">\r\n                            "
    + alias4((lookupProperty(helpers,"math")||(depth0 && lookupProperty(depth0,"math"))||alias2).call(alias1,(data && lookupProperty(data,"index")),"+",1,{"name":"math","hash":{},"data":data,"loc":{"start":{"line":26,"column":28},"end":{"line":26,"column":49}}}))
    + "\r\n                        </div>\r\n                        <div class=\"step-content\">\r\n                            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":29,"column":28},"end":{"line":29,"column":38}}}) : helper))) != null ? stack1 : "")
    + "\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n\r\n\r\n\r\n                <div class=\"widget usecase-step-image usecase-step-image-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":36,"column":73},"end":{"line":36,"column":83}}}) : helper)))
    + "\">\r\n\r\n                    <!-- Wrapped Start Screen and Device Image-->\r\n"
    + ((stack1 = (lookupProperty(helpers,"ifEquals")||(depth0 && lookupProperty(depth0,"ifEquals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"hardwareImage") : depth0),true,{"name":"ifEquals","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":39,"column":20},"end":{"line":80,"column":33}}})) != null ? stack1 : "")
    + "                    <!-- Wrapped Start Screen and Device Image-->\r\n\r\n                </div>\r\n\r\n            </div>\r\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"image\" \r\n                        data-hardware-image="
    + alias4(((helper = (helper = lookupProperty(helpers,"hardwareImage") || (depth0 != null ? lookupProperty(depth0,"hardwareImage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hardwareImage","hash":{},"data":data,"loc":{"start":{"line":41,"column":44},"end":{"line":41,"column":61}}}) : helper)))
    + " \r\n                        data-has-wrapper=\""
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"hasWrapper") : stack1), depth0))
    + "\"\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"coordinates") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":24},"end":{"line":46,"column":31}}})) != null ? stack1 : "")
    + "                        data-screen-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                        data-screen-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                        data-image-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                        data-image-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                        data-screen-position-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                        data-screen-position-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                        data-offset-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                        data-offset-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\">\r\n                        <div class=\"image-wrapper\">\r\n                            <img class=\"hardware\" src=\""
    + alias4(alias5(((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageURL") : stack1), depth0))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":56,"column":96},"end":{"line":56,"column":104}}}) : helper)))
    + "\">\r\n                            <img class=\"startScreen\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imageURL") || (depth0 != null ? lookupProperty(depth0,"imageURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imageURL","hash":{},"data":data,"loc":{"start":{"line":57,"column":58},"end":{"line":57,"column":70}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":57,"column":77},"end":{"line":57,"column":85}}}) : helper)))
    + "\">\r\n                        </div>\r\n                    </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        data-coordinates-x=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"coordinates") : depth0)) != null ? lookupProperty(stack1,"left") : stack1), depth0))
    + "\"\r\n                        data-coordinates-y=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"coordinates") : depth0)) != null ? lookupProperty(stack1,"top") : stack1), depth0))
    + "\"\r\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"image\" \r\n                        data-hardware-image="
    + alias4(((helper = (helper = lookupProperty(helpers,"hardwareImage") || (depth0 != null ? lookupProperty(depth0,"hardwareImage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hardwareImage","hash":{},"data":data,"loc":{"start":{"line":62,"column":44},"end":{"line":62,"column":61}}}) : helper)))
    + " \r\n                        data-has-wrapper=\""
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"hasWrapper") : stack1), depth0))
    + "\"\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"coordinates") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":24},"end":{"line":67,"column":31}}})) != null ? stack1 : "")
    + "                        data-screen-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                        data-screen-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                        data-image-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                        data-image-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                        data-screen-position-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                        data-screen-position-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                        data-offset-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                        data-offset-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\">\r\n                        <div class=\"image-wrapper\">\r\n                            <img class=\"startScreen\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imageURL") || (depth0 != null ? lookupProperty(depth0,"imageURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imageURL","hash":{},"data":data,"loc":{"start":{"line":77,"column":58},"end":{"line":77,"column":70}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":77,"column":77},"end":{"line":77,"column":85}}}) : helper)))
    + "\">\r\n                        </div>\r\n                    </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.lambda, alias5=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Usecase Template -->\r\n<div class=\"page usecase\">\r\n    <div class=\"wrapper\">\r\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\r\n        <h1 class=\"page-title\">"
    + alias5(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"usecase") : depth0)) != null ? lookupProperty(stack1,"topicName") : stack1), depth0))
    + "</h1>\r\n\r\n        <div class=\"widget topic-usecase\">\r\n\r\n            <button class=\"primary send-tutorial hide-desktop\">\r\n                <i class=\"material-icons-outlined\">share</i>\r\n                <span>"
    + alias5((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"page/usecase/send",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":22},"end":{"line":11,"column":55}}}))
    + "</span>\r\n            </button>\r\n\r\n            <h3 class=\"widget title copy-link hide-all\" data-href=\""
    + alias5((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"devices",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":14,"column":67},"end":{"line":14,"column":86}}}))
    + "/"
    + alias5(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias5(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"usecase") : depth0)) != null ? lookupProperty(stack1,"topicPath") : stack1), depth0))
    + "\">\r\n                <i class=\"material-icons file_copy\">insert_link</i>\r\n                <i class=\"material-icons check_circle\">check_circle</i>\r\n                "
    + alias5((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"page/usecase/copy-link",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":54}}}))
    + "\r\n            </h3>\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"usecase") : depth0)) != null ? lookupProperty(stack1,"steps") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":86,"column":21}}})) != null ? stack1 : "")
    + "            <div class=\"back-to-top\"><span>"
    + alias5((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"page/usecase/back-to-top",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":87,"column":43},"end":{"line":87,"column":83}}}))
    + "</span></div>\r\n\r\n        </div>\r\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"steps_navigation") || (depth0 != null ? lookupProperty(depth0,"steps_navigation") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"steps_navigation","hash":{},"data":data,"loc":{"start":{"line":90,"column":8},"end":{"line":90,"column":30}}}) : helper))) != null ? stack1 : "")
    + "\r\n    </div>\r\n    <div class=\"aside-content\">\r\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"share_topic") || (depth0 != null ? lookupProperty(depth0,"share_topic") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"share_topic","hash":{},"data":data,"loc":{"start":{"line":93,"column":8},"end":{"line":93,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\r\n    </div>\r\n</div>";
},"useData":true,"useDepths":true});