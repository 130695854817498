var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Manufacturers Template -->\r\n<div class=\"page manufacturers full\">\r\n    <div class=\"page-header\">\r\n        <div class=\"wrapper\">\r\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\r\n            <h1 class=\"page-title\">"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"page/manufacturers/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":35},"end":{"line":6,"column":75}}}))
    + "</h1>\r\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"card_filter") || (depth0 != null ? lookupProperty(depth0,"card_filter") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"card_filter","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\r\n        </div>\r\n    </div>\r\n    <div class=\"page-wrapper\">\r\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"manufacturer_list") || (depth0 != null ? lookupProperty(depth0,"manufacturer_list") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturer_list","hash":{},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":31}}}) : helper))) != null ? stack1 : "")
    + "\r\n    </div>\r\n</div>\r\n<!-- End Manufacturers Template -->";
},"useData":true});