var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"accordion-item\">\r\n        <div class=\"title item-title\">\r\n            <div class=\"image\">\r\n                <img src=\""
    + alias1(container.lambda((depths[1] != null ? lookupProperty(depths[1],"assets") : depths[1]), depth0))
    + "manufacturers/70x70/"
    + alias1(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":10,"column":59},"end":{"line":10,"column":67}}}) : helper)))
    + ".png\" alt=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":10,"column":78},"end":{"line":10,"column":86}}}) : helper)))
    + "\">\r\n            </div>\r\n            <div class=\"details\">\r\n                <label class=\"title\">\r\n                    "
    + alias1(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":28}}}) : helper)))
    + "\r\n                </label>\r\n                <p class=\"total-devices\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"deviceNumber") || (depth0 != null ? lookupProperty(depth0,"deviceNumber") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"deviceNumber","hash":{},"data":data,"loc":{"start":{"line":16,"column":41},"end":{"line":16,"column":57}}}) : helper)))
    + " "
    + alias1((lookupProperty(helpers,"pluralize")||(depth0 && lookupProperty(depth0,"pluralize"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"deviceNumber") : depth0),"widget/devices-by-brand/total-devices",{"name":"pluralize","hash":{},"data":data,"loc":{"start":{"line":16,"column":58},"end":{"line":16,"column":124}}}))
    + "</p>\r\n            </div>\r\n          <div class=\"icon-wrapper\">\r\n          <i class=\"material-icons accordion-expand\">expand_more</i>\r\n        </div>\r\n        </div>\r\n        <div class=\"content\">\r\n          <ul class=\"panel\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,(depths[1] != null ? lookupProperty(depths[1],"devices") : depths[1]),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":40,"column":21}}})) != null ? stack1 : "")
    + "          </ul>\r\n        </div>\r\n      </div>\r\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifEquals")||(depth0 && lookupProperty(depth0,"ifEquals"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0),(depths[1] != null ? lookupProperty(depths[1],"path") : depths[1]),{"name":"ifEquals","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":39,"column":29}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"card device "
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturer") || (depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturer","hash":{},"data":data,"loc":{"start":{"line":26,"column":39},"end":{"line":26,"column":55}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":26,"column":56},"end":{"line":26,"column":64}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"os") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":65},"end":{"line":26,"column":95}}})) != null ? stack1 : "")
    + "\">\r\n                    <a class=\"wrapper\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":27,"column":45},"end":{"line":27,"column":53}}}) : helper)))
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"manufacturers",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":27,"column":53},"end":{"line":27,"column":78}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturer") || (depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturer","hash":{},"data":data,"loc":{"start":{"line":27,"column":79},"end":{"line":27,"column":95}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":27,"column":96},"end":{"line":27,"column":104}}}) : helper)))
    + "\"\r\n                        data-navigate-to=\""
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"manufacturers",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":28,"column":42},"end":{"line":28,"column":67}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturer") || (depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturer","hash":{},"data":data,"loc":{"start":{"line":28,"column":68},"end":{"line":28,"column":84}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":28,"column":85},"end":{"line":28,"column":93}}}) : helper)))
    + "\">\r\n                        <div class=\"image\">\r\n                            <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"carouselImageURL") || (depth0 != null ? lookupProperty(depth0,"carouselImageURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"carouselImageURL","hash":{},"data":data,"loc":{"start":{"line":30,"column":38},"end":{"line":30,"column":58}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":30,"column":65},"end":{"line":30,"column":73}}}) : helper)))
    + "\">\r\n                        </div>\r\n                        <div class=\"details\">\r\n                            <span class=\"brand\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturerName") || (depth0 != null ? lookupProperty(depth0,"manufacturerName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturerName","hash":{},"data":data,"loc":{"start":{"line":33,"column":48},"end":{"line":33,"column":68}}}) : helper)))
    + "</span>\r\n                            <span class=\"type\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\r\n                            <h3 class=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":35,"column":46},"end":{"line":35,"column":54}}}) : helper)))
    + "</h3>\r\n                        </div>\r\n                    </a>\r\n                </li>  \r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"os") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Widget Devices by Brand Accordion-->\r\n<div class=\"widget devices-by-brand devices-by-brand-accordion\" data-collapse-all=\"collapse\">\r\n  <h2 class=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":3,"column":20},"end":{"line":3,"column":29}}}) : helper)))
    + "</h2>\r\n  <p class=\"description\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":4,"column":25},"end":{"line":4,"column":40}}}) : helper)))
    + "</p>\r\n  <div class=\"accordion\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"brands") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":44,"column":13}}})) != null ? stack1 : "")
    + "  </div>\r\n</div>";
},"useData":true,"useDepths":true});