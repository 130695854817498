var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- TV Template -->\r\n<div class=\"page device-type full\">\r\n    <div class=\"page-header\">\r\n        <div class=\"wrapper\">\r\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\r\n            <h1 class=\"page-title\">TV</h1>\r\n\r\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"most_viewed_devices") || (depth0 != null ? lookupProperty(depth0,"most_viewed_devices") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"most_viewed_devices","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":37}}}) : helper))) != null ? stack1 : "")
    + "\r\n        </div>\r\n    </div>\r\n    <div class=\"page-wrapper\">\r\n        <div class=\"control-list\">\r\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"devices_by_control") || (depth0 != null ? lookupProperty(depth0,"devices_by_control") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"devices_by_control","hash":{},"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":36}}}) : helper))) != null ? stack1 : "")
    + "\r\n        </div>\r\n        <div class=\"decoder-list\">\r\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"devices_by_brand") || (depth0 != null ? lookupProperty(depth0,"devices_by_brand") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"devices_by_brand","hash":{},"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":34}}}) : helper))) != null ? stack1 : "")
    + "\r\n        </div>\r\n    </div>\r\n</div>\r\n<!--End TV Template -->";
},"useData":true});