var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"card "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":36}}}) : helper)))
    + "\" data-path=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":8,"column":49},"end":{"line":8,"column":57}}}) : helper)))
    + "\">\r\n                <a class=\"wrapper\">\r\n                    <div class=\"details\">\r\n                        <label class=\"title\">\r\n                            "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":12,"column":28},"end":{"line":12,"column":36}}}) : helper)))
    + "\r\n                        </label>\r\n                    </div>\r\n                        <i class=\"icon os-icon "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":15,"column":47},"end":{"line":15,"column":55}}}) : helper)))
    + "\"></i>\r\n                </a>\r\n            </li>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"card device "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"os") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":35},"end":{"line":24,"column":66}}})) != null ? stack1 : "")
    + " active\">\r\n                <a class=\"wrapper\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":25,"column":41},"end":{"line":25,"column":49}}}) : helper)))
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"application",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":25,"column":49},"end":{"line":25,"column":72}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":25,"column":73},"end":{"line":25,"column":81}}}) : helper)))
    + "\" data-navigate-to=\""
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"application",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":25,"column":101},"end":{"line":25,"column":124}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":25,"column":125},"end":{"line":25,"column":133}}}) : helper)))
    + "\">\r\n                    <div class=\"image\">\r\n                        <img src=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"softwareImage") : depth0)) != null ? lookupProperty(stack1,"imageURL") : stack1), depth0))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":27,"column":67},"end":{"line":27,"column":75}}}) : helper)))
    + "\">\r\n                    </div>\r\n                    <div class=\"details\">\r\n                        <span class=\"type\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\r\n                        <h3 class=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":31,"column":42},"end":{"line":31,"column":50}}}) : helper)))
    + "</h3>\r\n                    </div>\r\n                </a>\r\n            </li>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"path","hash":{},"data":data,"loc":{"start":{"line":24,"column":48},"end":{"line":24,"column":56}}}) : helper)))
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Widget Software by OS -->\r\n<div class=\"widget software-by-os\">\r\n    <h2 class=\"title\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/software_by_os/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":22},"end":{"line":3,"column":65}}}))
    + "</h2>\r\n    <p class=\"description\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/software_by_os/description",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":27},"end":{"line":4,"column":76}}}))
    + "</p>\r\n    <div class=\"filter widget os-list filter-list\">\r\n        <ul class=\"panel\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"oss") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":18,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\r\n    </div>\r\n    <div class=\"devices widget software-list item-list\">\r\n        <ul class=\"panel\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"softwares") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":35,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\r\n    </div>\r\n</div>\r\n<!-- End Widget Software by OS -->";
},"useData":true});