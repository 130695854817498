var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"card "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":14,"column":32},"end":{"line":14,"column":40}}}) : helper)))
    + "\">\r\n\r\n                    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":16,"column":29},"end":{"line":16,"column":37}}}) : helper)))
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"manufacturers",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":16,"column":37},"end":{"line":16,"column":62}}}))
    + "/"
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"brand") : depths[1])) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":16,"column":81},"end":{"line":16,"column":89}}}) : helper)))
    + "\"\r\n                        data-navigate-to=\""
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"manufacturers",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":17,"column":42},"end":{"line":17,"column":67}}}))
    + "/"
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"brand") : depths[1])) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":17,"column":86},"end":{"line":17,"column":94}}}) : helper)))
    + "\" class=\"wrapper\">\r\n\r\n\r\n                        \r\n                        <!-- Wrapped Start Screen and Device Image-->\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasWrapper") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":22,"column":24},"end":{"line":39,"column":31}}})) != null ? stack1 : "")
    + "                        <!-- Wrapped Start Screen and Device Image-->\r\n\r\n\r\n\r\n                        <div class=\"details\">\r\n                            <p class=\"brand-name\">"
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"brand") : depths[1])) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</p>\r\n                            <h3 class=\"title\">\r\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":47,"column":32},"end":{"line":47,"column":40}}}) : helper)))
    + "\r\n                            </h3>\r\n                        </div>\r\n                    </a>\r\n                </li>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"image\" data-screen-size-x=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                            data-screen-size-y=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                            data-image-size-x=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                            data-image-size-y=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                            data-screen-position-x=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\r\n                            data-screen-position-y=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\r\n                            data-offset-x=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\" data-offset-y=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\">\r\n                            <div class=\"image-wrapper\">\r\n                                <img class=\"hardware\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"imageURL") : stack1), depth0))
    + "\" alt=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":31,"column":90},"end":{"line":31,"column":98}}}) : helper)))
    + "\">\r\n                                <img class=\"startScreen\" src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"startScreenImageURL") || (depth0 != null ? lookupProperty(depth0,"startScreenImageURL") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"startScreenImageURL","hash":{},"data":data,"loc":{"start":{"line":32,"column":62},"end":{"line":32,"column":85}}}) : helper)))
    + "\" alt=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":32,"column":92},"end":{"line":32,"column":100}}}) : helper)))
    + "\">\r\n                            </div>\r\n                        </div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"image\">\r\n                            <img class=\"startScreen\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"startScreenImageURL") || (depth0 != null ? lookupProperty(depth0,"startScreenImageURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startScreenImageURL","hash":{},"data":data,"loc":{"start":{"line":37,"column":58},"end":{"line":37,"column":81}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":37,"column":88},"end":{"line":37,"column":96}}}) : helper)))
    + "\">\r\n                        </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Brand Template -->\r\n<div class=\"page brand full\">\r\n    <div class=\"page-header\">\r\n        <div class=\"wrapper\">\r\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\r\n            <h1 class=\"page-title\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"brand") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\r\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"card_filter") || (depth0 != null ? lookupProperty(depth0,"card_filter") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"card_filter","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\r\n        </div>\r\n    </div>\r\n    <div class=\"page-wrapper\">\r\n        <div class=\"widget device-list\">\r\n            <ul class=\"panel\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"devices") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":52,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\r\n        </div>\r\n\r\n    </div>\r\n</div>\r\n<!-- End Brand Template -->";
},"useData":true,"useDepths":true});