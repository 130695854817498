import { Widgets } from "./Widgets";

export class DeviceList extends Widgets{
    constructor(selector) {
        super(selector);
        this.element.querySelectorAll(".card .image").forEach(elem=>{
            this.wrappDeviceImage(elem);
        });
        this.simpleNavigate();
    }
}