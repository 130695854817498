export default
HTMLDocument.prototype.ready = function () {
    return new Promise(function (resolve, reject) {
        if (document.readyState === 'complete') {
            resolve(document);
        } else {
            document.addEventListener('DOMContentLoaded', function () {
                resolve(document);
            });
        }
    });
}
HTMLElement.prototype.replace = function (element) {
    this.innerHTML = "";
    this.appendChild(element);
}
HTMLElement.prototype.empty = function () {
    this.innerHTML = "";
}
HTMLElement.prototype.off = function () {
    var new_element = this.cloneNode(true);
    this.parentNode.replaceChild(new_element, this);
    console.log(this);
}
String.prototype.params = function () {
    try {
        let search = this.replace("?","").split("&");
        let params = [];
        for (let i=0; i<search.length; i++) {
            search[i] = search[i].split("=");
            params[search[i][0]] = decodeURI(search[i][1]).split("+").join(" ");
        }
        return params;
    } catch (error) {
        console.log(error);
    }
}
