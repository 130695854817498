var Handlebars = require("../../../../projeto/csr-ultrgaz/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"card "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":36}}}) : helper)))
    + "\" data-path=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":8,"column":49},"end":{"line":8,"column":57}}}) : helper)))
    + "\">\r\n                <a class=\"wrapper\">\r\n                    <div class=\"image\">\r\n                        <img src=\""
    + alias4(container.lambda((depths[1] != null ? lookupProperty(depths[1],"assets") : depths[1]), depth0))
    + "manufacturers/70x70/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":11,"column":67},"end":{"line":11,"column":75}}}) : helper)))
    + ".png\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":11,"column":86},"end":{"line":11,"column":94}}}) : helper)))
    + "\">\r\n                    </div>\r\n                    <div class=\"details\">\r\n                        <label class=\"title\">\r\n                            "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":15,"column":28},"end":{"line":15,"column":36}}}) : helper)))
    + "\r\n                        </label>\r\n                        <p class=\"total-devices\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"deviceNumber") || (depth0 != null ? lookupProperty(depth0,"deviceNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"deviceNumber","hash":{},"data":data,"loc":{"start":{"line":17,"column":49},"end":{"line":17,"column":65}}}) : helper)))
    + " "
    + alias4((lookupProperty(helpers,"pluralize")||(depth0 && lookupProperty(depth0,"pluralize"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"deviceNumber") : depth0),"widget/dts-devices-by-brand/total-devices",{"name":"pluralize","hash":{},"data":data,"loc":{"start":{"line":17,"column":66},"end":{"line":17,"column":136}}}))
    + "</p>\r\n                    </div>\r\n                    <div class=\"icon-holder\">\r\n                        <i class=\"material-icons\">chevron_right</i>\r\n                    </div>\r\n                </a>\r\n            </li>\r\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"card device "
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturer") || (depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturer","hash":{},"data":data,"loc":{"start":{"line":30,"column":35},"end":{"line":30,"column":51}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":30,"column":52},"end":{"line":30,"column":60}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"os") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":61},"end":{"line":30,"column":91}}})) != null ? stack1 : "")
    + "\">\r\n                <a class=\"wrapper\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":31,"column":41},"end":{"line":31,"column":49}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturer") || (depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturer","hash":{},"data":data,"loc":{"start":{"line":31,"column":49},"end":{"line":31,"column":65}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":31,"column":66},"end":{"line":31,"column":74}}}) : helper)))
    + "/"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"category") : depths[1]), depth0))
    + "/"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"topic") : depths[1]), depth0))
    + "\"\r\n                    data-navigate-to=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturer") || (depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturer","hash":{},"data":data,"loc":{"start":{"line":32,"column":38},"end":{"line":32,"column":54}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":32,"column":55},"end":{"line":32,"column":63}}}) : helper)))
    + "/"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"category") : depths[1]), depth0))
    + "/"
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"topic") : depths[1]), depth0))
    + "\">\r\n                    <div class=\"image\">\r\n                        <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"carouselImageURL") || (depth0 != null ? lookupProperty(depth0,"carouselImageURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"carouselImageURL","hash":{},"data":data,"loc":{"start":{"line":34,"column":34},"end":{"line":34,"column":54}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":34,"column":61},"end":{"line":34,"column":69}}}) : helper)))
    + "\">\r\n                    </div>\r\n                    <div class=\"details\">\r\n                        <span class=\"brand\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturerName") || (depth0 != null ? lookupProperty(depth0,"manufacturerName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturerName","hash":{},"data":data,"loc":{"start":{"line":37,"column":44},"end":{"line":37,"column":64}}}) : helper)))
    + "</span>\r\n                        <span class=\"type\">"
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\r\n                        <h3 class=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":39,"column":42},"end":{"line":39,"column":50}}}) : helper)))
    + "</h3>\r\n                    </div>\r\n                </a>\r\n            </li>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"os") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Widget Devices by Brand -->\r\n<div class=\"widget devices-by-brand dts-item-by-filter\">\r\n    <h2 class=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":3,"column":22},"end":{"line":3,"column":31}}}) : helper)))
    + "</h2>\r\n    <p class=\"description\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":4,"column":27},"end":{"line":4,"column":42}}}) : helper)))
    + "</p>\r\n    <div class=\"filter widget manufacturer-list filter-list\">\r\n        <ul class=\"panel\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"brands") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":24,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\r\n    </div>\r\n    <div class=\"devices widget device-list item-list\">\r\n        <ul class=\"panel\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"devices") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":43,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\r\n    </div>\r\n</div>\r\n<!-- End Widget Devices by Brand -->";
},"useData":true,"useDepths":true});